import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { filterByCurrentPage } from '../utils/searchHelpers';
import { IReckoningAcceptedCases, IReckoningDashboard } from '../interfaces/user/reckoning/reckoning';
import { IContractData, IUserContractData } from '../interfaces/user/contract/contract';
import { AreaKey, IAcceptedOrRejectedCase } from '../interfaces/admin/cases/cases';
import { ContractType } from '../interfaces/admin/contract/contract';
import { mapKeyToId } from '../components/pages/user/myReckoning/myAcceptedCases/MyAcceptedCases.helper';

export class ReckoningStore {
  reckoningDashboard: IReckoningDashboard | null = null;
  reckoningAcceptedCases: IAcceptedOrRejectedCase[] | null = null;
  contractType: ContractType = 'none';
  companyContractResponse: IContractData | null = null;
  userContractResponse: IUserContractData | null = null;
  areasCases: null | Set<AreaKey> = null;

  isReckoningAcceptedCasesLoaded: boolean = false;
  isReckoningDashboardLoaded: boolean = false;
  cardsPerPage = 10;
  currentPage = 1;

  selectedValue: number = -1;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setReckoningDashboard(reckoningDashboard: IReckoningDashboard) {
    this.reckoningDashboard = reckoningDashboard;
    this.setContractType(reckoningDashboard.companyContract, reckoningDashboard.userContract);
    this.isReckoningDashboardLoaded = true;
  }

  resetReckoningDashboard() {
    this.isReckoningDashboardLoaded = false;
  }

  setReckoningAcceptedCases(reckoningAcceptedCases: IReckoningAcceptedCases) {
    this.reckoningAcceptedCases = reckoningAcceptedCases.items.sort((a, b) => +a.inProgress - +b.inProgress);
    this.companyContractResponse = reckoningAcceptedCases.companyContractResponse || null;
    this.userContractResponse = reckoningAcceptedCases.userContractResponse || null;
    this.areasCases = new Set(reckoningAcceptedCases.items.map(item => item.area as AreaKey));

    if (this.selectedValue === -1 && this.areasCases && this.areasCases.size > 1) {
      const iterator = this.areasCases.values();
      this.setSelectedValue(mapKeyToId(iterator.next().value));
    } else if (this.areasCases.size === 1 && reckoningAcceptedCases.items.length > 0) {
      this.setSelectedValue(mapKeyToId(reckoningAcceptedCases.items[0].area));
    }
    this.setContractType(reckoningAcceptedCases.companyContract, reckoningAcceptedCases.userContract);
    this.isReckoningAcceptedCasesLoaded = true;
  }

  setContractType(companyContract?: boolean, userContract?: boolean) {
    this.contractType = companyContract ? 'company' : userContract ? 'user' : 'none';
  }

  resetReckoningAcceptedCases() {
    this.isReckoningAcceptedCasesLoaded = false;
  }

  get filteredMyReckoningsByCurrentPage() {
    return this.reckoningAcceptedCasesFilteredData?.filter(filterByCurrentPage(this.currentPage, this.cardsPerPage));
  }

  get isFilteredCasesListNotEmpty() {
    return this.reckoningAcceptedCasesFilteredData && this.reckoningAcceptedCasesFilteredData.length !== 0;
  }

  setCurrentPage = (value: number) => {
    this.currentPage = value;
  };

  get pageCount() {
    return Math.ceil(
      this.reckoningAcceptedCasesFilteredData && this.isFilteredCasesListNotEmpty ? this.reckoningAcceptedCasesFilteredData?.length / this.cardsPerPage : 1
    );
  }

  get reckoningAcceptedCasesFilteredData() {
    return this.reckoningAcceptedCases ? this.reckoningAcceptedCases.filter(el => mapKeyToId(el.area) === this.selectedValue) : null;
  }

  setSelectedValue = (value: number) => {
    this.selectedValue = value;
    this.setCurrentPage(1);
  };

  resetSelectedValue = () => {
    this.selectedValue = -1;
  };

}
