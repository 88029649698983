import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthStore, useProgressBarNeuroStore } from '../../../../store/hooks';
import { IBreadcrumbsElement } from '../../../../interfaces/user/breadcrumbs';
import { GeneralLink, UserLink, generateLink } from '../../../../utils';
import { useModal } from '../../../shared/modal/useModal.vm';
import { ModalType } from '../../../../constants/modalType';
import { IImage } from '../../../../interfaces/user/image';
import { RoleCategory } from '../../../../constants/projectUsers';

export const useDraftCaseNeuro = (isReset?: boolean) => {
  const navigate = useNavigate()
  const [afterReset, setAfterReset] = useState(false);
  const {areas} = useAuthStore()
  const [index, setIndex] = useState(-1);
  const [images, setImages] = useState<IImage[]>([]);
  const { isModalOpened, closeModal, handleModal } = useModal();

  const { id } = useParams();
  const progressBarStore = useProgressBarNeuroStore();

  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      link: UserLink.MY_CASES,
      name: 'Moje opisy przypadków'
    },
    {
      name: 'Dodawanie nowego opisu przypadku'
    }];

  useEffect(() => {
    if (isReset && !afterReset) {
      setAfterReset(true);
      progressBarStore.resetProgressBar();
      progressBarStore.setIsLoaded(true);
    }
  }, [id, isReset, progressBarStore, afterReset]);

  const imageHandler = (index: number, images: IImage[]) => {
    handleModal(ModalType.LIGHTBOX);
    setIndex(index);
    setImages(images);
  };

  if(areas?.find(area => area.shortName !== 'neuro')){
    navigate(generateLink(RoleCategory.USER, GeneralLink.PAGE_404))
  }

  return { breadcrumbs, imageHandler, index, isModalOpened, closeModal, images };
};