import React from 'react';
import {
  CaseCardContainer,
  DataLarge,
  DataMiddle,
  Date,
  Icon,
  StyledButton,
  StyledNext,
  StyledStatus
} from './CaseCard.styled';
import { dateStringify, generateAreaLink, generateLink, timeStringify, UserLink } from '../../../../utils';
import success from '../../../../assets/icon/success.svg';
import pending from '../../../../assets/icon/pending.svg';
import draft from '../../../../assets/icon/edit.svg';
import close from '../../../../assets/icon/close.svg';
import theme from '../../../../theme/theme';
import { ReactComponent as DoubleArrow } from '../../../../assets/icon/doubleArrow.svg';
import { useNavigate } from 'react-router-dom';
import { RoleCategory } from '../../../../constants/projectUsers';
import { CaseStatus } from '../../../../interfaces/user/draftCaseNeuro/draft';

interface IProps {
  id: number;
  status: CaseStatus;
  customId: string;
  patient?: string;
  title: string;
  updatedAt: string;
  type?: string;
  maxWidth?: string;
}

export interface IStatusIcons {
  [key: string]: string;
}

interface IStatus {
  [key: string]: string;
}


const statusIcons: IStatusIcons = {
  [CaseStatus.DRAFT]: draft,
  [CaseStatus.ACCEPT]: success,
  [CaseStatus.REJECT]: close,
  [CaseStatus.SENT]: pending
};

const statusColors: IStatus = {
  [CaseStatus.DRAFT]: theme.colors.blue1,
  [CaseStatus.ACCEPT]: theme.colors.turquoise2,
  [CaseStatus.REJECT]: theme.colors.red,
  [CaseStatus.SENT]: theme.colors.yellow
};

const statusName: IStatus = {
  [CaseStatus.DRAFT]: 'szkic',
  [CaseStatus.ACCEPT]: 'zaakceptowany',
  [CaseStatus.REJECT]: 'do edycji',
  [CaseStatus.SENT]: 'oczekujący'
};

const statusFurther: IStatus = {
  [CaseStatus.DRAFT]: 'dokończ',
  [CaseStatus.ACCEPT]: '',
  [CaseStatus.REJECT]: '',
  [CaseStatus.SENT]: ''
};
export const CaseCard: React.FC<IProps> = ({ id, status, type, customId, patient, title, updatedAt, maxWidth }) => {
  const navigate = useNavigate();
  const icon = statusIcons[status];
  const color = statusColors[status];
  const name = statusName[status];
  const furtherStep = statusFurther[status];

  const navigateToDraftCaseHandler = () => {

    if (type) {
      if (status === CaseStatus.DRAFT || status === CaseStatus.REJECT) {
        navigate(generateLink(RoleCategory.USER, generateAreaLink(type, 'edit') as UserLink, id));
      } else {
        navigate(generateLink(RoleCategory.USER, generateAreaLink(type, 'view') as UserLink, id));
      }
    }

  };

  return (

    <CaseCardContainer maxWidth={maxWidth} onClick={navigateToDraftCaseHandler}>
      <Icon color={color}><img src={icon} alt={'status'} /></Icon>
      <StyledStatus color={color}>Status: <span>{name}</span></StyledStatus>
      <Date>{timeStringify(updatedAt)} {` - `} {dateStringify(updatedAt)}</Date>
      <DataMiddle>
        ID opisu przypadku <span>{customId}</span>
      </DataMiddle>
      <DataLarge>
        Tytuł: <span>{title}</span>
      </DataLarge>
      {patient && <DataMiddle>
        ID pacjenta <span>{patient.toUpperCase()}</span>
      </DataMiddle>}
      <StyledButton onClick={navigateToDraftCaseHandler}>
        {furtherStep}
        <StyledNext><DoubleArrow /></StyledNext>
      </StyledButton>
    </CaseCardContainer>
  );
};


