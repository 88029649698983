import React, {FC, useState} from 'react';
import {SuccessIcon} from '../../../../../shared/modal/modalIcons/SuccessIcon';
import publicImage from '../../../../../../assets/img/public.png';
import unPublicImage from '../../../../../../assets/img/unpublic.png';
import {
  ConfirmationTextModal
} from '../../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled';
import {ContentModal} from '../../../../../shared/modal/ContentModal';
import {Button} from '../../../../../shared/button/Button';
import {RadioButtons} from './AcceptCase.styled';

interface IProps {
  handleSave: (isPublic: boolean) => void;
}

export const AcceptCase: FC<IProps> = ({handleSave}) => {

  const [isPublic, setIsPublic] = useState(true);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPublic(event.target.value === 'public');
  };
  return (<ContentModal
      iconType={<SuccessIcon/>}
      text={<ConfirmationTextModal><p>Właśnie akceptujesz opis przypadku...</p><p>Zdecyduj <b>czy opis przypadku ma być
        publiczny </b>dla wszystkich użytkowników czy<b> ma być nie publiczny</b> (widoczny tylko dla publikującego
        autora)</p></ConfirmationTextModal>}>
      <>
        <RadioButtons>
          <label>
            <input onChange={onChange} name={'isPublic'} type={'radio'} value={'public'} checked/>
            <img alt='public' src={publicImage}/>
          </label>
          <label>
            <input onChange={onChange} name={'isPublic'} type={'radio'} value={'unpublic'}/>
            <img alt='unpublic' src={unPublicImage}/>
          </label>
        </RadioButtons>
        <Button text={'Zapisz'} clickHandler={() => handleSave(isPublic)} size={'small'} width={'189px'}
                backgroundColor={'black'} color={'white'}/>
      </>
    </ContentModal>
  );
};
