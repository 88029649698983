import React, { useState } from 'react';
import { Table, TableRow, Typography } from '@mui/material';
import { useStatisticsStore } from '../../../../../store/hooks';
import { ActionButton } from '../../../../shared/customTables/button/ActionButton';
import { TableCellData } from '../../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled';
import { createTableRows } from '../../../../shared/customTables/utils/createTableRows';
import theme from '../../../../../theme/theme';
import { IAction, IHeadCell, TableSortOrder } from '../../../../../interfaces/common/table';
import { ICasesTable } from '../../../../../interfaces/admin/statistics/statistics';
import { ReactComponent as ArrowAccordion } from './../../../../../assets/icon/blackArrowAccordion.svg';

export const useReckoningsVm = () => {
  const [openedItems, setOpenedItems] = useState<string[]>([]);

  const {
    isStatisticsTableLoaded,
    currentReckoningsPage,
    reckoningsDataTableByCurrentPage,
    setReckoningsCurrentPage,
    reckoningsPageCount
  } = useStatisticsStore();
  const statisticsStore = useStatisticsStore();

  const headCells: IHeadCell[] = [
    { id: 'title', numeric: false, label: 'Nazwa dokumentu' },
    { id: 'type', numeric: false, label: 'Rodzaj' },
    { id: 'author', numeric: false, label: 'Publikujący' },
    { id: 'areas', numeric: false, label: 'Obszar terapeutyczny' },
    { id: 'amount', numeric: false, label: 'Kwota' },
    { id: 'cases', numeric: false, label: '' }
  ];

  const sortOrderByHandler = (orderBy: string) => {
    statisticsStore.setOrderBy(orderBy);
  };
  const sortOrderHandler = (order: TableSortOrder) => {
    statisticsStore.setOrder(order);
  };

  const generateCasesTable = (cases: ICasesTable[], id: string) => {
    return openedItems.includes(id) ? <TableCellData colSpan={5} width={'100%'} sx={{ padding: '12px 0' }}>
      <Table sx={{ overflow: 'hidden' }}>
        <TableRow aria-rowspan={2}>
          <Typography variant={'h6'} sx={{ fontSize: '16px', fontWeight: 700, width: '200%' }}
                      color={theme.colors.dark1}>
            Rozliczone opisy przypadków:</Typography>
        </TableRow>
        <TableRow aria-rowspan={5}>
          <TableCellData width={'15%'}>ID OPISU</TableCellData>
          <TableCellData width={'85%'}>NAZWA OPISU</TableCellData>
        </TableRow>
        {(cases as { customId: string, title: string }[])?.map((el) =>
          <TableRow key={el.customId}>
            <TableCellData>{el.customId}</TableCellData>
            <TableCellData>{el.title}</TableCellData>
          </TableRow>
        )}
      </Table>
    </TableCellData> : <></>;
  };

  const tableDataRows = createTableRows(reckoningsDataTableByCurrentPage ?? [], headCells, false, generateCasesTable);

  const toggleAccordion = (id: string) => {
    if (openedItems.includes(id)) {
      setOpenedItems(openedItems.filter(el => el !== id));
    } else {
      setOpenedItems([...openedItems, id]);
    }
  };

  const cellsSwitcher = (keyName: string, value: string | number | boolean | string[], onClick?: () => void, id?: number, type?: string, customId?: string) => {
    const specialValue = value === 0 || value === '' ? '-' : value;
    const areas = typeof value === 'object' && keyName === 'areas' ? value.join(', ') : value;
    switch (keyName) {
      case 'id':
      case 'cases':
        return null;
      case 'areas':
        return <TableCellData>{areas}</TableCellData>;
      default:
        return <TableCellData>{specialValue?.toString()}</TableCellData>;
    }
  };

  const generateOpenAction = (id: number | string) => {
    return <ActionButton
      id={id}
      actionHandler={() => toggleAccordion(id as string)}
      background={theme.colors.white}
      border={theme.colors.white}
      rotate={openedItems.includes(id as string)}
      width={52} actionElement={<ArrowAccordion />} />;
  };

  const generateActions = () => {
    const actions: IAction[] = [
      {
        key: 'accordion',
        custom: generateOpenAction,
        width: 52
      }
    ];
    return actions;
  };

  return {
    cellsSwitcher,
    headCells,
    tableDataRows,
    isStatisticsTableLoaded,
    setReckoningsCurrentPage,
    reckoningsPageCount,
    currentReckoningsPage,
    generateActions,
    sortOrderByHandler,
    sortOrderHandler
  };
};